<template>
  <div :class="item.class">
    <div v-for="column in item.columns" :key="column._uid" :class="column.class">
      <sb-render v-for="_item in column.content" :key="_item._uid" :item="_item" />
    </div>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'Row',
  extends: Blok
}
</script>
